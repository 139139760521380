import {
  EnvironmentOutlined,
  UserOutlined,
  PhoneOutlined,
  ShopOutlined,
  ClockCircleOutlined,
  CommentOutlined,
  FieldTimeOutlined,
  MobileOutlined,
  CopyOutlined,
} from "@ant-design/icons";

import {
  Typography,
  Space,
  Avatar,
  Select,
  Badge,
  Input,
  Divider,
  Popover,
  Button,
  message,
  Tooltip,
  Tag,
  Row,
} from "antd";
import { BikerIcon } from "../../comonents/global";
import { useOrderView } from "./store";
import { useEffect } from "react";
import dayjs from "dayjs";
import { getInRangeAddress } from "../../api";
import { useQuery } from "react-query";

const copy = require("clipboard-copy");

const { Text } = Typography;
const { Option } = Select;

export const OrderInfo = () => {
  const {
    order,
    setClientAddressID,
    clientAddressID,
    setClientComment,
    clientComment,
    orderStatusHistory,
    clientAdress,
    setClientAdress,
    clientAdressName,
    setClientAdressName,
    setTotalValue,
  } = useOrderView();

  useEffect(() => {
    if (order && order?.order) {
      setTotalValue(order?.order?.totalValue);
      setClientAddressID(order?.order?.clientAddressID);
      setClientComment(order?.order?.clientComment);
      setClientAdress(order?.order?.clientAddressInfo?.address);
      setClientAdressName(order?.order?.clientAddressInfo?.addressName);
    }
  }, [order]);

  const copyLink = (val) => {
    let successPromise = copy(val);
    if (successPromise) {
      message.success("Copy to clipboard");
    } else {
      message.error("Faild Copy !");
    }
  };

  const isStatus = (status) => {
    return orderStatusHistory.find((el) => el.status === status) ? true : false;
  };

  const { data: addressData, refetch: inRangeRefetch } = useQuery(
    ["address", order?.order?.vendorID, order?.order?.clientID],
    getInRangeAddress,
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  return (
    <>
      <Text>Order Info</Text>
      <Badge.Ribbon
        color="red"
        style={{ display: order?.order?.preOrder ? "inherit" : "none" }}
        text={
          <Space>
            <FieldTimeOutlined />
            Pre-Order
          </Space>
        }
      >
        <div
          className="order-info"
          style={{ paddingTop: order?.order?.preOrder ? "15px" : "0" }}
        >
          <section style={{ width: "100%", overflow: "hidden" }}>
            <Space size={16} align="start">
              <Avatar
                size="default"
                style={{ background: "#0000ff", fontSize: 20 }}
              >
                <UserOutlined />
              </Avatar>
              <Space size={0} direction="vertical">
                <Space>
                  <Text
                    style={{ margin: 0, lineHeight: 1.4, fontWeight: "bold" }}
                  >
                    {order?.order?.client || "No client name ! ..."}{" "}
                    <Button
                      className="chat-btn"
                      type="text"
                      icon={<CommentOutlined />}
                    />
                  </Text>
                  <Text style={{ fontSize: 12 }}>
                    <Space size={4}>
                      <MobileOutlined />
                      {order?.order?.phone || "...."}
                    </Space>
                  </Text>
                  <Tooltip placement="top" title="Copy User Phone">
                    <Button
                      onClick={() => copyLink(order?.order?.phone || "....")}
                      size="small"
                      type="text"
                      icon={<CopyOutlined />}
                    />
                  </Tooltip>

                  <Text style={{ marginTop: 3, lineHeight: 1.4, }}>
                    | #{order?.order?.clientID || "...."}
                  </Text>
                </Space>
              </Space>
            </Space>
            <Text style={{ fontSize: 12, lineHeight: 6 }} type="secondary">
              <Space size={0}>
                <EnvironmentOutlined
                  size="default"
                  style={{ color: "#0000ff", fontSize: 28 }}
                />

                <Select
                  disabled={
                    isStatus("BIKER_ACCEPTED") ||
                    isStatus("REJECTED") ||
                    isStatus("CANCELLED")
                  }
                  dropdownMatchSelectWidth={false}
                  style={{ color: "#0000ff", maxWidth: "8vw" }}
                  value={clientAdress}
                  onChange={(val) => {
                    setClientAddressID(val);
                    setClientAdress(val);
                    setClientAdressName(val);
                  }}
                  bordered={false}
                >
                  {addressData?.data?.map((el) => (
                    <Option key={el.id} value={el.id} disabled={!el.inrange}>
                      {el.address}
                    </Option>
                  ))}
                </Select>
                <Text style={{ fontSize: 12 }}>
                  <Space size={4}>
                    <PhoneOutlined />
                    {order?.order?.addressPhone || "...."}
                  </Space>
                </Text>
                <Tooltip placement="top" title="Copy Address Phone">
                  <Button
                    onClick={() =>
                      copyLink(order?.order?.addressPhone || "....")
                    }
                    size="small"
                    type="text"
                    icon={<CopyOutlined />}
                  />
                </Tooltip>
              </Space>
            </Text>{" "}
            <div style={{ width: "100%" }}>
              <Text>Adress Title</Text>

              <Select
                disabled={false}
                dropdownMatchSelectWidth={false}
                style={{ color: "#0000ff", maxWidth: "8vw" }}
                value={clientAdressName}
                onChange={(val) => {
                  setClientAddressID(val);
                }}
                bordered={false}
                onClick={() => inRangeRefetch()}
                dropdownStyle={{ display: "none" }}
                suffixIcon={null}
              >
                {addressData?.data?.map((el) => (
                  <Option key={el.id} value={el.id} disabled={!el.inrange}>
                    {el.addressName}
                  </Option>
                ))}
              </Select>
              <Space direction="vertical" style={{ width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      border: "1px solid #ccc",
                      width: "40%",
                      height: "40%",
                      display: "flex",
                      padding: "4px",
                    }}
                  >
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <Text style={{ fontSize: 12 }}>Street</Text>
                      <Tag style={{ fontSize: 12 }}>
                        {order?.order?.clientAddressInfo?.street}
                      </Tag>
                    </Space>
                  </div>

                  <div
                    style={{
                      border: "1px solid #ccc",
                      width: "40%",
                      height: "40%",
                      display: "flex",
                      padding: "4px",
                    }}
                  >
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <Text style={{ fontSize: 12 }}>Building</Text>
                      <Tag style={{ fontSize: 12 }}>
                        {order?.order?.clientAddressInfo?.building}
                      </Tag>
                    </Space>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      border: "1px solid #ccc",
                      width: "40%",
                      height: "40%",
                      display: "flex",
                      padding: "4px",
                    }}
                  >
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <Text style={{ fontSize: 12 }}>Floor</Text>
                      <Tag style={{ fontSize: 12 }}>
                        {order?.order?.clientAddressInfo?.floor}
                      </Tag>
                    </Space>
                  </div>
                  <div
                    style={{
                      border: "1px solid #ccc",
                      width: "40%",
                      height: "40%",
                      display: "flex",
                      padding: "4px",
                    }}
                  >
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <Text style={{ fontSize: 12 }}>Appartment</Text>
                      <Tag style={{ fontSize: 12 }}>
                        {order?.order?.clientAddressInfo?.apartment}
                      </Tag>
                    </Space>
                  </div>
                </div>
              </Space>
            </div>
            <div
              style={{
                border: "1px solid #ccc",
                width: "100%",
                height: "40%",
                display: "flex",
                padding: "4px",
                marginTop: "6px",
              }}
            >
              <Space direction="vertical" style={{ width: "100%" }}>
                <Text style={{ fontSize: 12 }}>Nearest Point</Text>
                <Tag style={{ fontSize: 12 }}>
                  {order?.order?.clientAddressInfo?.nearby}
                </Tag>
              </Space>
            </div>
            <Divider style={{ margin: "10px 0" }} />
            <Input.TextArea
              disabled={
                isStatus("VENDOR_ACCEPTED") ||
                isStatus("PICKED_UP") ||
                isStatus("REJECTED") ||
                isStatus("CANCELLED")
              }
              rows={2}
              value={clientComment}
              onChange={(e) => setClientComment(e.target.value)}
              placeholder="Client Comment..."
            />
          </section>

          {/* <Divider style={{ margin: "16px 0" }} /> */}
          <section style={{ width: "100%" }}>
            <Space size={16} align="start">
              <Avatar
                size="large"
                style={{ background: "#0000ff", fontSize: 20 }}
              >
                <ShopOutlined style={{ paddingTop: 10 }} />
              </Avatar>
              <Space size={0} direction="vertical">
                <Row>
                  <Text
                    style={{ margin: 0, lineHeight: 1.4, fontWeight: "bold" }}
                    level={5}
                  >
                    {order?.order?.vendor || "No vendor name ! ..."}{" "}
                    <Button
                      className="chat-btn"
                      type="text"
                      icon={<CommentOutlined />}
                    />
                  </Text>

                  <Text
                    style={{ marginTop: 5, lineHeight: 1.4, }}

                  >
                    | Vendor ID: #{order?.order?.vendorID || "No vendor ID ! ..."}{" "}
                  </Text>
                </Row>

                <Space>
                  <Text
                    style={{ fontSize: 12, lineHeight: 0 }}
                    type="secondary"
                  >
                    <Space size={4}>
                      <EnvironmentOutlined />
                      <Popover
                        content={
                          <span className="sub-title">
                            {order?.vendor?.address}
                          </span>
                        }
                      >
                        <span className="sub-title">
                          {order?.vendor?.address?.length > 30
                            ? order?.vendor?.address.substr(0, 30) + "..."
                            : order?.vendor?.address || "..."}
                        </span>
                      </Popover>
                    </Space>
                  </Text>
                </Space>
                <Text
                  style={{ fontSize: 12, lineHeight: 0 }}
                  type="secondary"
                >

                  <Space size={4} >
                    <PhoneOutlined />
                    Cashier 1: {order?.vendor?.resturantPhone || "..."}
                  </Space>

                  <Space size={4}>
                    <PhoneOutlined />
                    | Cashier 2: {order?.vendor?.resturantMgmtPhone || "..."}
                  </Space>
                </Text>
              </Space>
            </Space>
          </section>
          {/* <Divider style={{ margin: "16px 0" }} /> */}
          {order?.order?.bikerOrderID && (
            <section style={{ width: "100%" }}>
              <Space size={16} align="start">
                <Avatar
                  size="large"
                  style={{ background: "#0000ff", fontSize: 20 }}
                >
                  <BikerIcon style={{ paddingTop: 10 }} />
                </Avatar>
                <Space size={0} direction="vertical">
                  <Row>
                    <Text
                      style={{ margin: 0, lineHeight: 1.4, fontWeight: "bold" }}
                      level={5}
                    >
                      {order?.order?.biker_name || "No biker name ! ..."}{" "}
                      <Button
                        className="chat-btn"
                        type="text"
                        icon={<CommentOutlined />}
                      />
                    </Text>
                    <Text
                      style={{ marginTop: 5, lineHeight: 1.4, }}
                      level={5}
                    >
                      | Biker ID: #{order?.order?.biker_id || "No biker ID ! ..."}{" "}

                    </Text>
                  </Row>


                  <Space>
                    <Text style={{ fontSize: 12 }}>
                      <Space size={4}>
                        <PhoneOutlined />
                        {order?.order?.biker_phone || "..."}
                      </Space>
                    </Text>
                    |
                    <Text
                      style={{ fontSize: 12, lineHeight: 0 }}
                      type="secondary"
                    >
                      <Space size={4}>
                        <ClockCircleOutlined />
                        <span className="sub-title">
                          {dayjs(order?.order?.bikerPickupDateTime).format(
                            "YYYY-MM-DD hh:mm A"
                          ) || "..."}
                        </span>
                      </Space>
                    </Text>
                  </Space>
                </Space>
              </Space>
            </section>
          )}
        </div>
      </Badge.Ribbon>
    </>
  );
};
